import { Injectable } from '@angular/core';
import { Api, Claim, CustomerDetails } from '@domgen/dgx-components';
import { format } from 'date-fns';

enum CallbackKey {
  CallbackSlot = 'CallbackSlot',
  FaultDate = 'FaultDate',
}

@Injectable({ providedIn: 'root' })
export class CallbackHelperService {
  getCallbackPayload(claim: Claim, desiredTime: string): Api.CallbackRequest {
    return {
      Fields: [
        {
          Identifier: CallbackKey.CallbackSlot,
          Value: `${format(new Date(desiredTime), 'dd/MM/yyyy hh:mm:ss')}`,
        },
        ...this.getKeysAndValue(claim?.callbackQA as { [key: string]: string }),
      ],
    };
  }

  getKeysAndValue(
    data: { [key: string]: string } | CustomerDetails = {}
  ): Api.CallbackRequestField[] {
    return Object.entries(data)
      .filter(([key, value]) => value.length > 1)
      .map(([key, value]) => {
        const isFaultDate = key === CallbackKey.FaultDate;

        return {
          Identifier: `${key}`,
          Value: `${
            isFaultDate ? format(new Date(value), 'dd/MM/yyyy') : value
          }`,
        };
      });
  }
}
