import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, RepairDetailsGuard } from '@domgen/feature-shell';
import { ApplianceDetailsGuard } from '@domgen/feature-shell';
import { routes as claimRoutes } from '@domgen/feature-shell';

const routes: Routes = [
  {
    path: 'repair-details',
    canActivate: [AuthGuard, RepairDetailsGuard],
    loadChildren: () =>
      import('@domgen/legacy-feature-repair-details').then(
        (m) => m.ClaimsLegacyFeatureRepairDetailsModule
      ),
  },
  {
    path: 'appliance-details',
    canActivate: [AuthGuard, ApplianceDetailsGuard],
    loadChildren: () =>
      import('@domgen/legacy-feature-appliance-details').then(
        (m) => m.LegacyClaimsFeatureApplianceDetailsModule
      ),
  },
  {
    path: 'confirmation',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/legacy-feature-confirmation').then(
        (m) => m.ClaimsLegacyFeatureConfirmationModule
      ),
  },
  {
    path: 'maintenance',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/legacy-feature-maintenance-signpost').then(
        (module) => module.ClaimsLegacyFeatureMaintenanceSignpostModule
      ),
  },
  ...claimRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top', // Add options right here
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AOClaimsFeatureShellRoutingModule {}
