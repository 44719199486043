<header [ngClass]="themeClass">
  <a class="cookieProBannerClosedPreventScroll"></a>
  <div class="container">
    <div class="flex header-wrapper" *ngIf="links">
      <a [href]="links.homeURL" class="header__logo" data-automation="home">
        <img src="/assets/images/dg-logo.svg" alt="Domestic & General" />
      </a>

      <div class="header__account">
        <ul class="flex">
          <li>
            <a
              class="header-link"
              data-automation="person"
              [ngClass]="{ 'header-link--active': navOpen }"
              (click)="navOpen = !navOpen"
            >
              <svg class="icon icon-person">
                <use xlink:href="#icon-person"></use>
              </svg>
            </a>

            <nav
              class="header__account-nav"
              [ngClass]="{ 'header__account-nav--open': navOpen }"
            >
              <ul>
                <li>
                  <a
                    [href]="links.personalDetailsURL"
                    class="text--center"
                    data-automation="personalDetails"
                  >
                    <span>My details</span>
                  </a>
                </li>
                <li>
                  <a
                    (click)="logoutClick()"
                    class="text--center logout"
                    data-automation="logOut"
                  >
                    <span>Log out</span>
                  </a>
                </li>
              </ul>
            </nav>
          </li>
          <li>
            <a
              [href]="links.plansURL"
              class="header-link"
              [ngClass]="{ 'header-link--active': false }"
              data-automation="home1"
            >
              <svg class="icon icon-home">
                <use xlink:href="#icon-home"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
